<template>
	<DialogBase
		:dialogWidth="1100"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		v-bind="$attrs"
	>
		<template v-slot:title>
			{{ selectedModel ? `Update` : `Create` }} Short Code
		</template>

		<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
			<FormMaker :schema="form_structure" :model="form_data"></FormMaker>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				{{ selectedModel ? `Update` : `Create` }}
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, validAlphaNum } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null },
			user: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					payload: {
						is_active: "Yes",
						is_based_on_application: "Yes",
						name: null,
						body_type: "text",
						body: null
					}
				}
			};
		},
		mounted() {
			if (this.selectedModel) {
				this.form_data = Object.assign({}, this.selectedModel);
			} else {
			}
		},
		computed: {
			form_structure() {
				let form = {
					payload: {
						name: {
							type: "text",
							name: "name",
							label: "Short Code Name",
							...this.$helpers.formFieldsBasicLayout,
							rules: [
								required(),
								validAlphaNum("Only Alphabets numeric allowed.")
							],
							disabled: !!this.selectedModel
						},
						is_active: {
							type: "btn-toggle",
							name: "is_active",
							label: "Is Active?",
							...this.$helpers.formFieldsBasicLayout,
							options: ["Yes", "No"],
							mandatory: true,
							rules: [required],
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 }
						},
						is_based_on_application: {
							type: "btn-toggle",
							name: "is_based_on_application",
							label: "Based On Application?",
							...this.$helpers.formFieldsBasicLayout,
							options: ["Yes", "No"],
							mandatory: true,
							rules: [required],
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 }
						},
						body_type: {
							type: "btn-toggle",
							name: "body_type",
							label: "Body Type?",
							...this.$helpers.formFieldsBasicLayout,
							options: ["html", "text"],
							mandatory: true,
							rules: [required],
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
							hidden: this.form_data.payload.is_based_on_application == "Yes"
						},
						body: {
							type:
								this.form_data.payload.is_based_on_application == "Yes"
									? "text"
									: this.form_data.payload.body_type == "html"
									? "text-editor"
									: "textarea",
							name: "body",
							label:
								this.form_data.payload.is_based_on_application == "Yes"
									? "Application Key Path"
									: this.form_data.payload.body_type == "html"
									? "Html Body"
									: "Text Body",
							...this.$helpers.formFieldsBasicLayout,
							rows: 6,
							rules: []
						}
					}
				};
				return form;
			}
		},
		watch: {
			"form_data.payload.is_based_on_application"(newVal, oldVal) {
				if (newVal && oldVal && newVal != oldVal && newVal == "Yes") {
					this.form_data.payload.body = helpers.removeHtmlTags(
						this.form_data.payload.body
					);
					this.form_data.payload.body_type = "text";
				}
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			refresh(val = false) {
				this.$emit("refresh");
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					...this.form_data
				};

				if (this.selectedModel)
					return await this.post(
						`${this.baseUrl}/agent-portal/update-system-short-code`,
						data,
						true
					).then(data => {
						if (data == "undefined") return;
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.refresh();
							this.closeDialog();
						}
					});

				return await this.post(
					`${this.baseUrl}/agent-portal/create-system-short-code`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
						this.closeDialog();
					}
				});
			}
		}
	};
</script>
